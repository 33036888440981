<template>
    <ThingInfo v-if="!loading && thing" title="Edit Thing" :editThingId="thing?.id" :startData="startData" />
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"
import ThingInfo from "../components/things/editThing/ThingInfo.vue"
import capitalize from "../utils/Capitalize";
import CapitalizeWords from "../utils/CapitalizeWords";
import match from "../utils/Match";
import goodSplit from "../utils/PySplit";

const store = useStore()
const route = useRoute()
const router = useRouter()

const thing = ref(null)
const loading = ref(false)

const flattenStorageParentPath = (acc, storage) => {
    if (storage == null)
        return acc.reverse()
    else {
        acc.push({ id: storage.id })
        return flattenStorageParentPath(acc, storage.parent)
    }
}

const startData = computed(() => {
    const ret = {
        basic: {
            ...thing.value,
            tags: (thing.value?.tags ?? []).map(tag => CapitalizeWords(tag.label.title)).join(";"),
            categories: (thing.value?.categories ?? []).map(cat => ({
                id: cat.branch[0]?.id,
                name: cat.label.name,
                parentId: cat.branch[1]?.id,
                path: cat.branch.slice().reverse().map((item, i, arr) => ({
                    parentId: arr[i - 1]?.id.toString(),
                    id: item.id.toString(),
                })),
            }))
        },
        extra: {
            placeId: thing.value?.storage?.space?.venue?.id,
            spaceId: thing.value?.storage?.space?.id,
            locPath: flattenStorageParentPath([], thing.value?.storage),
            locCurrent: { id: thing.value?.storage?.id, name: thing.value?.storage?.name, type: "storage" },
        },
        images: thing.value?.medialibrary?.images.map(img => ({
            id: img.id,
            preview: img.image,
            uuid: img.uuid,
            meta: {
                hasError: false,
                fileToBig: false,
                formatAllowed: true
            },
        })),
        custom: Object.fromEntries(
            thing.value.properties
                .map(prop => [prop.id, match(prop.propertyType, {
                    "BOOLEAN": () => match(prop.value?.toLowerCase(), {
                        "true": () => "true",
                        "false": () => "false",
                        "_": () => [],
                    }),
                    "OPTION_M": () => goodSplit(";", prop.value ?? "").map(capitalize),
                    "_": () => prop.value === null ? [] : capitalize(prop.value),
                })])
                .filter(([, val]) => !Array.isArray(val) || val.length !== 0)
        )
    }
    return ret
})

onMounted(async () => {
    thing.value = await store.dispatch("getThing", route.params.id)
    loading.value = false
})

watch(loading, (isLoading, wasLoading) => {
    if (!isLoading && wasLoading && store.getters.getId !== thing.value?.owner?.profile)
        router.push({ name: "Things" })
})
</script>
